@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Oxygen&display=swap');

* * {
  box-sizing: border-box; }

body {
  background-color: #474a51;
  color: #d0d0d0;
  font-size: 1em;
  line-height: 1.6;
  font-weight: 400;
  font-family: 'Oxygen';

  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  display: block;
  widht: 100%;
  font-family: "Fira sans"; }

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0; }

.container {
  margin: 0;
  height: 100%;
  padding: 0;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; }

.row {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center; }

input, button {
  margin: 4px;
  padding: 6px;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  font-size: 32px;
  color: #c0c0c0;
  border: 1px solid #a0a0a0;
  background-color: transparent; }

input {
  min-width: 100px;
  max-width: 400px;
  flex-grow: 2; }

.errormsg {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  span {
    color: white;
    background-color: red;
    text-transform: uppercase;
    padding: 8px; } }
