@import "video.js/src/css/vjs.scss";

$primary-foreground-color: #fff;
$primary-background-color: #252323;
$slider-bg-color: lighten($primary-background-color, 33%);
$center-big-play-button: false;

.video-js {
  font-size: 10px;
  color: $primary-foreground-color; }

.video-js * {
  outline: none !important; }

.video-js .vjs-big-play-button {
  font-size: 3em;
  $big-play-width: 1.5em;
  $big-play-height: 1.5em;

  line-height: 1.4em;
  height: $big-play-height;
  width: $big-play-width;

  border: 1px solid red;
  border-radius: 0;
  background-color: rgba($primary-background-color, 0.5);

  left: 5%;
  top: 95%;
  margin-top: -($big-play-height); }


.video-js:hover .vjs-big-play-button {
  background-color: red;
  border: 1px solid red !important; }

.video-js .vjs-control-bar, .video-js .vjs-menu-button .vjs-menu-content {
  background-color: rgba($primary-background-color, 0.5); }

.video-js .vjs-load-progress {
  background: rgba($slider-bg-color, 0.5); }

.video-js .vjs-load-progress div {
  background: rgba($slider-bg-color, 0.75); }

.video-js .vjs-poster {
  outline: none; }

.video-js .vjs-playback-rate {
  display: none; }

.video-js .vjs-picture-in-picture-control {
  display: none; }

.video-js .vjs-seek-to-live-control {
  display: none; }
